<template>
  <div
    :class="{ '-confetti': confetti }"
    class="visual"
  >
    <h1 class="visual-title">
      <img
        :src="require(`@images/common/${locale}/sublogo.png`)"
        :alt="$t('top001')"
        class="sublogo"
      >
      <img
        :src="require(`@images/common/${locale}/logo.png`)"
        :alt="$t('top003')"
        class="logo"
      >
    </h1>
  </div>
</template>

<script>
export default {
  name: 'Visual',

  props: {
    confetti: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    locale () {
      return this.$route.params.lang
    },
  },

  methods: {
  }
}
</script>

<i18n lang="yaml" src="@/locales/top.yaml"></i18n>

<style lang="scss" scoped>
.visual {
  padding-top: 35px;
  min-height: 427px;

  &.-confetti {
    background: url(#{$img-path}common/bg_confetti.png) no-repeat center top;
  }

  > .visual-title {
    display: flex;
    flex-direction: column;
    align-items: center;

    > .sublogo {
      margin-top: 27px;
    }
    > .logo {
      margin-top: -30px;
    }
  }
}
</style>